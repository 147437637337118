
.main[data-v-358fde3e] {
  color: #333;
  background: white;
}
.main.scroll[data-v-358fde3e] {
  margin-top: 56px;
  padding: 10px 20px 20px 10px;
  max-width: 1000px;
}
.main.fullscreen[data-v-358fde3e] {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.main.fullscreen article[data-v-358fde3e] {
  height: 100%;
  width: 100%;
}
