
.page[data-v-2a1bf5cd] {
  max-width: 100%;
}
.container[data-v-2a1bf5cd] {
  width: fit-content;
}
.menu[data-v-2a1bf5cd] {
  color: #fff;
  background: #000;
  display: flex;
  font-size: 20px;
  padding: 5px 4px 3px;
  align-items: center;
  height: 40px;
}
.controls[data-v-2a1bf5cd] {
  color: #fff;
  background: #333;
  display: flex;
  font-size: 20px;
  padding: 5px 4px 3px;
  align-items: center;
  height: 40px;
}
.featureButtonExpand[data-v-2a1bf5cd] {
  flex: 1 0 auto;
}
.featureButtonFixed[data-v-2a1bf5cd] {
  flex: none;
  text-align: center;
}
.featureValue[data-v-2a1bf5cd] {
  display: block;
  cursor: pointer;
  margin: 4px 2px;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}
.featureValueFixed[data-v-2a1bf5cd] {
  flex: none;
  text-align: center;
}
.featureButton[data-v-2a1bf5cd] {
  display: block;
  cursor: pointer;
  margin: 4px 8px;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  min-width: 34px;
}
.controlFeature-auto > .controlFeature-autoIcon[data-v-2a1bf5cd] {
  transform: rotate(180deg);
  color: #fff;
}
.controlFeature-auto > .controlFeature-autoEnabled[data-v-2a1bf5cd] {
  transform: rotate(0);
  color: rgb(96, 231, 96);
}
.controlFeature-speeddown[data-v-2a1bf5cd] {
  text-align: right;
}
.controlFeature-speedup[data-v-2a1bf5cd] {
  text-align: left;
}
.controlFeature-speedStep[data-v-2a1bf5cd] {
  display: inline-block;
  width: 7px;
  height: 100%;
  background: #aaa;
  margin-right: 2px;
}
.controlFeature-speedStep[data-v-2a1bf5cd]:last-of-type {
  margin-right: 0;
}
.controlFeature-speedStep-active[data-v-2a1bf5cd] {
  background: rgb(96, 231, 96);
}
.controlFeature-frameNumber[data-v-2a1bf5cd] {
  font-size: 18px;
}
.map[data-v-2a1bf5cd] {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 300px;
}
.mapImage[data-v-2a1bf5cd] {
  max-width: 100%;
  display: block;
}
.mapInvalidImage[data-v-2a1bf5cd] {
  min-width: 360px;
  height: 500px;
  background: #b7f8ef;
  padding: 50px;
}
.mapOverlays[data-v-2a1bf5cd],
.mapOverlay-pan[data-v-2a1bf5cd] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.mapMessage[data-v-2a1bf5cd] {
  background: #fff;
  border-bottom: 2px solid #000;
  padding: 20px;
}
.mapPanButton[data-v-2a1bf5cd] {
  width: 40px;
  height: 40px;
  color: #000;
  font-weight: bold;
  position: absolute;
}
.mapPanButton > *[data-v-2a1bf5cd] {
  font-size: 40px;
}
.mapPanButton[data-v-2a1bf5cd]::before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px;
}
.mapPanButton-NW[data-v-2a1bf5cd] {
  top: 7%;
  left: 3%;
}
.mapPanButton-NW > *[data-v-2a1bf5cd] {
  transform: rotate(-45deg);
}
.mapPanButton-N[data-v-2a1bf5cd] {
  top: 7%;
  left: 50%;
  transform: translate(-50%, 0);
}
.mapPanButton-N > *[data-v-2a1bf5cd] {
  transform: rotate(0);
}
.mapPanButton-NE[data-v-2a1bf5cd] {
  top: 7%;
  right: 3%;
}
.mapPanButton-NE > *[data-v-2a1bf5cd] {
  transform: rotate(45deg);
}
.mapPanButton-E[data-v-2a1bf5cd] {
  top: 50%;
  transform: translate(0, -50%);
  right: 3%;
}
.mapPanButton-E > *[data-v-2a1bf5cd] {
  transform: rotate(90deg);
}
.mapPanButton-SE[data-v-2a1bf5cd] {
  bottom: 7%;
  right: 3%;
}
.mapPanButton-SE > *[data-v-2a1bf5cd] {
  transform: rotate(135deg);
}
.mapPanButton-S[data-v-2a1bf5cd] {
  bottom: 7%;
  left: 50%;
  transform: translate(-50%, 0);
}
.mapPanButton-S > *[data-v-2a1bf5cd] {
  transform: rotate(180deg);
}
.mapPanButton-SW[data-v-2a1bf5cd] {
  bottom: 7%;
  left: 3%;
}
.mapPanButton-SW > *[data-v-2a1bf5cd] {
  transform: rotate(-135deg);
}
.mapPanButton-W[data-v-2a1bf5cd] {
  top: 50%;
  transform: translate(0, -50%);
  left: 3%;
}
.mapPanButton-W > *[data-v-2a1bf5cd] {
  transform: rotate(-90deg);
}
.mapOverlay-panels[data-v-2a1bf5cd] {
  position: relative;
  height: 100%;
}
.mapPanel[data-v-2a1bf5cd] {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  min-width: 260px;
  height: 100%;
  overflow: hidden;
}
.mapPanelContent[data-v-2a1bf5cd] {
  background: #efefef;
  padding: 20px 16px;
  overflow-y: auto;
  max-height: calc(100% - 48px);
}
.mapPanelFullSize[data-v-2a1bf5cd] {
  width: 100%;
}
.mapPanelTitle[data-v-2a1bf5cd] {
  font-size: 120%;
}
.mapPanel-product li[data-v-2a1bf5cd] {
  margin-bottom: 12px;
}
.mapPanel-locationSearchType[data-v-2a1bf5cd] {
  margin-top: 5px;
}
.mapPanel-locationSearchType > input[data-v-2a1bf5cd] {
  transform: scale(1.5);
  margin-right: 10px;
}
.mapPanel-locationSearchType > span[data-v-2a1bf5cd] {
  margin-right: 10px;
}
.mapPanel-locationResults[data-v-2a1bf5cd] {
  padding: 20px 0;
}
.mapPanel-location input[type="text"][data-v-2a1bf5cd] {
  width: 100%;
  font-size: 18px;
  padding: 6px 10px;
}
.mapPanel ul[data-v-2a1bf5cd] {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 12px;
  list-style: none;
}
.mapPanel ul > li[data-v-2a1bf5cd] {
  padding-top: 7px;
}
.mapPanel ul > li[data-v-2a1bf5cd]:first-of-type {
  padding-top: 0;
}
.mapPanel a[data-v-2a1bf5cd],
.mapPanel a[data-v-2a1bf5cd]:hover,
.mapPanel a[data-v-2a1bf5cd]:visited,
.mapPanel a[data-v-2a1bf5cd]:active {
  color: blue;
}
.info[data-v-2a1bf5cd] {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0;
  box-sizing: border-box;
  max-width: 550px;
}
.infoLabel[data-v-2a1bf5cd] {
  padding: 5px;
  white-space: nowrap;
}
.infoValue[data-v-2a1bf5cd] {
  padding: 5px;
}
.urlList[data-v-2a1bf5cd] {
  margin: 0;
  padding: 0;
  list-style: none;
}
.urlListItem[data-v-2a1bf5cd] {
  margin-left: 0;
  padding-bottom: 4px;
}
.productBar[data-v-2a1bf5cd] {
  padding: 8px 10px;
  background: #fff;
  border-bottom: 2px solid;
  font-size: 14px;
  cursor: pointer;
}
.productArrow svg[data-v-2a1bf5cd] {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
}
.productRotateArrow svg[data-v-2a1bf5cd] {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}
.mapPanel-product ul[data-v-2a1bf5cd] {
  padding-left: 0;
}
.mapPanelActionBar[data-v-2a1bf5cd] {
  padding: 10px 20px 10px 10px;
  background: #dedede;
}
.mapPanelActionBar input[data-v-2a1bf5cd] {
  margin: 0;
  padding: 5px 10px;
  border: 1px solid #a5a5a5;
  background-color: #eee;
}
