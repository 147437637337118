
.content-enhanced[data-v-1818fb3b] {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  grid-template-columns: 100%;
}
.content-map[data-v-1818fb3b] {
  display: block;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: #efefef;
}
