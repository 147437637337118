
.button[data-v-20d67518] {
  cursor: pointer;
  border: 0;
  border-radius: 0.3rem;
  font-family: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  margin-left: 0.2em;
  margin-bottom: 0.2em;
  outline: none;
  padding: 0.8rem 1.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background-color: #fff;
  color: #0071bc;
  box-shadow: inset 0 0 0 2px #0071cc;
}
.button[data-v-20d67518]:link,
.button[data-v-20d67518]:hover,
.button[data-v-20d67518]:visited,
.button[data-v-20d67518]:active {
  text-decoration: none;
}
.button[data-v-20d67518]:hover {
  background-color: #f3f3f3;
}
.button.primary[data-v-20d67518] {
  background-color: #0071bc;
  box-shadow: inset 0 0 0 2px #0071bc;
  color: #fff;
}
.button.primary[data-v-20d67518]:hover {
  background-color: #025892;
}
.button.callto[data-v-20d67518] {
  background-color: #f60;
  box-shadow: inset 0 0 0 2px #f60;
  border: none;
  color: #fff;
}
.button.callto[data-v-20d67518]:hover {
  background-color: #333;
  box-shadow: inset 0 0 0 2px #333;
}
.button.disabled[data-v-20d67518] {
  background-color: #efefef;
  box-shadow: inset 0 0 0 2px #efefef;
  color: #aaa;
  cursor: default;
}
.button.large[data-v-20d67518] {
  font-size: 120%;
}
.button.small[data-v-20d67518] {
  font-size: 75%;
  padding: 0.4rem 1rem;
}
.button.connect-left[data-v-20d67518] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.button.connect-right[data-v-20d67518] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button.standout[data-v-20d67518] {
  display: block;
  padding: 10px 20px;
  margin: 10px 0;
}
