
.content[data-v-78a83c19] {
  padding: 20px 20px 20px 10px;
}
.page-message[data-v-78a83c19] {
  margin-top: 20px;
}
h2[data-v-78a83c19] {
  font-size: 130%;
}
